<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :items="items"
      :total-items="totalItems"
      :total-pages.sync="totalPages"
      :headers="headers"
      title="Peringkat"
      subtitle="Halaman untuk menampilkan peringkat"
      add-btn="Tambah Data"
    >
    </DataTablePagination>
  </v-card>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'

export default {
  name: 'Leaderboard',
  components: {
    DataTablePagination,
  },
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalItems: 10,
      headers: [
        { text: 'No', value: 'no' },
        { text: 'JUDUL', value: 'judul' },
        { text: 'SET WAKTU', value: 'waktu' },
        { text: 'TANGGAL  UPLOAD', value: 'tanggal' },
        { text: 'TAMPIL PADA', value: 'tampil_pada' },
        { text: 'STATUS', value: 'chip' },
        { text: 'Actions', value: 'actions' },
      ],
      items: [
        {
          no: '#',
          judul: 'Ingat Belajar !!!',
          waktu: 'Permanen',
          tanggal: '12:20, 29/12/2021',
          tampil_pada: '30/12/2021',
          chip: 'active',
        },
      ],
    }
  },
}
</script>

<style>
</style>
